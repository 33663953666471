//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import {
  faCommentAltLines,
  faSignOut,
  faAngleDown,
  faMoon,
} from '@fortawesome/pro-light-svg-icons';
import {
  faMeContributor,
} from '@motionelements/assets/src/js/me-font/icons-channels2022';
import {
  faUser,
  faUserVneck,
  faBuildings,
} from '@motionelements/assets/src/js/me-font/icons-fa6';

export default {
  name: 'user-nav-dropdown',
  props: {
    variant: {
      type: String,
    },
    onShow: Function,
  },
  components: {
    NavTheme: () => import('./NavTheme.vue'),
  },
  data() {
    return {
      faUser,
      faCommentAltLines,
      faSignOut,
      faUserVneck,
      faBuildings,
      faAngleDown,
      faMoon,
      faMeContributor,
      avatarUrlError: false,
    };
  },
  computed: {
    ...mapState({
      member: state => state.user.member,
      isContributor: state => state.user.isArtist,
      isReseller: state => state.user.isReseller,
      isOrganization: state => state.user.isOrganization,
    }),
    ...mapGetters({
      isMember: 'user/isMember',
      avatarUrl: 'user/getAvatarUrl',
      displayName: 'user/getDisplayName',
    }),
    feedbackFormUrl() {
      let url;
      switch (this.$i18n.locale) {
        case 'ja':
          url = 'https://airtable.com/shr03yuytXp66zgjB';
          break;
        case 'ko':
          url = 'https://airtable.com/shrsPi8NgMFcbQFQr';
          break;
        case 'zh-hant':
          url = 'https://airtable.com/shrfvL4rF1upSZxMp';
          break;
        default:
          url = 'https://airtable.com/shrwG9spdLuaahm47';
      }
      url += `?prefill_Email=${encodeURIComponent(this.userEmail)}`;
      return url;
    },
    userEmail() {
      return this.member.email;
    },
    organizationInfo() {
      return this.isMember ? this.member.organization : '';
    },
    showAvatar() {
      return !this.avatarUrlError && this.avatarUrl && !this.avatarUrl.includes('https%3A%2F%2Fstatic.motionelements.com%2Fimg%2Fmember%2F');
    },
  },
  methods: {
    emitOnShow() {
      this.$emit('onShow');
    },
    onAvatarUrlError(event) {
      this.avatarUrlError = true;
      event.onerror = null;
    },
  },
};
